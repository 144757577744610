import React, { Component, Fragment } from 'react';
import { Icon, Menu, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import { TableButton, } from '../../styles/BasicStyles';
import moment from 'moment';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { VenueColors, getVenueColor, getWeddingContacts, getWeddingEmails, getWeddingName, getWeddingStatus } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { UpdateReserveRelatedInfo } from '../../infra/requests/ReserveRequests';
import Alerts from '../../components/alert/Alert';
import { GetTextColor } from '../../infra/services/text/textUtils';
import momentTimezone from 'moment-timezone';
import { AuthGoogleDrive } from '../../infra/requests/ReportsRequests';
import { UnblockMeetings, UpdateReserveNotes } from '../../infra/requests/WeddingRequests';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import { DownloadSchedules, GetSchedulesPaginated, RegisterSchedulePresence, SoftDeleteInternalSchedule, SoftDeleteSchedule, UpdateInternalSchedule, UpdateOrganizationSchedule, UpdateSchedule, UpdateScheduleNotes, UpdateSchedules, UploadSchedulesToDrive } from '../../infra/requests/SchedulesRequests';
import AccessGuestModal from '../weddings/AccessGuestModal';
import { LocalLabel } from '../weddings/Styles';
import { SpanContact } from '../weddings/WeddingsPage';
import SchedulePresenceModal, { PresenceStatus } from './SchedulePresenceModal';
import ScheduleInternalModal from './ScheduleInternalModal';
import OpenLockIcon from '../../assets/icons/open_lock.svg';
import ReactSVG from 'react-svg';
import { setDataToUpdateForReserveWedding } from '../reserves/reservesUtils';
import ScheduleNotesModal from './ScheduleNotesModal';
import { GetScheduleRowColor, GetScheduleWarningRowColor, ScheduleTypes } from './utilSchedules';
import { SpanNotes } from './ScheduleStyles';
import MeetingModal from './MeetingModal';

const { confirm } = Modal;

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover esta reunião?</div>
    <div>
      Toda a informação desta reunião será removida da plataforma.
    </div>
  </div>
);

class MeetingsPageDetail extends Component {
  state = {
    loading: false,
    loadingChild: false,

    organizerMeetings: true,

    columns: [
      {
        title: 'Horário',
        width: '50px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.type == ScheduleTypes.ORGANIZATION
              ? moment.utc(data.startDate).local().format('HH:mm')
              : <div>
                {moment.utc(data.startDate).local().format('HH:mm')}
                < Icon type="swap-right" />
                {moment.utc(data.endDate).local().format('HH:mm')}
              </div>
          };
        }
      },
      {
        title: 'Tipo',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.type == ScheduleTypes.INTERNAL
              ? 'Reunião interna'
              : 'Organização'
          };
        }
      },
      {
        title: 'Local',
        width: '50px',
        render: data => {
          const local = getVenueColor(data, this.state.colors);
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: local
              ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
              : null
          };
        }
      },
      {
        title: 'Colaborador',
        width: '100px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.type == ScheduleTypes.ORGANIZATION
              ? (data?.attendedBy?.name || '')
              // : data?.attendedUsers && data?.attendedUsers?.length > 0
              //   ? data?.attendedUsers?.map(m => m?.name).join(', ')
              : null
          };
        }
      },
      {
        title: 'Noivos / Reunião',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.type == ScheduleTypes.INTERNAL
              ? data?.name
              : getWeddingName(data.wedding)
          };
        }
      },
      {
        title: 'Email',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.type != ScheduleTypes.INTERNAL
              ? <Tooltip title={getWeddingEmails(data.wedding)}>
                <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
                  {getWeddingEmails(data.wedding)}
                </SpanContact>
              </Tooltip>
              : null
          };
        }
      },
      {
        title: 'Tel',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.type != ScheduleTypes.INTERNAL
              ? <Tooltip title={getWeddingContacts(data.wedding)}>
                <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
                  {getWeddingContacts(data.wedding)}
                </SpanContact>
              </Tooltip>
              : null
          };
        }
      },
      {
        title: 'Idioma',
        width: '100px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.language == 'pt_en'
              ? 'Preferencialmente em Inglês, mas pode ser em Português'
              : data?.language == 'en'
                ? 'Inglês'
                : 'Português'
          };
        }
      },
      {
        title: 'Tipo de Atendimento',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.attendingType?.name?.pt || ''
          };
        }
      },
      {
        title: 'Presença',
        width: '50px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.presence && data?.presence == 'P'
              ? <Tag color="green">Presente</Tag>
              : data?.presence && data?.presence == 'M'
                ? <Tag color="red">Faltou</Tag>
                : ''
          };
        }
      },
      {
        title: 'Notas',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(this.state.colors, data),
                color: GetTextColor(GetScheduleRowColor(this.state.colors, data))
              }
            },
            children: data?.type !== ScheduleTypes.INTERNAL
              ? <Tooltip title={'Editar'}>
                <SpanNotes
                  dangerouslySetInnerHTML={{
                    __html: (data?.wedding?.meetings || '')
                  }}
                  onClick={($event) => this.editScheduleNotes($event, data)}>
                </SpanNotes>
              </Tooltip>
              : null
          };
        }
      },
      {
        title: 'Ponto de Situação',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleWarningRowColor(data),
                color: GetTextColor(GetScheduleWarningRowColor(data))
              }
            },
            children: data?.type == ScheduleTypes.INTERNAL
              ? <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.weddingSummary || ''
                }}>
              </SpanNotes>
              : null
          };
        }
      },
      {
        title: 'Acções',
        width: '70px',
        // fixed: 'right',
        render: data => {
          const now = momentTimezone().tz('Europe/Lisbon');
          const scheduleDate = momentTimezone(data.date).tz('Europe/Lisbon');

          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <div onClick={e => e.stopPropagation()}>
              {data?.type !== ScheduleTypes.INTERNAL
                && (isCurrentUserAdmin() || (!isCurrentUserAdmin() && data?.wedding?.status == WeddingStatus.RESERVE))
                && <Tooltip title={data?.wedding?.status !== WeddingStatus.RESERVE ? 'Editar Acessos/Padrão' : 'Editar Noivos/Info Geral'}>
                  <TableButton onClick={e => this.editGeneral(e, data)}>
                    <Icon type="team" />
                  </TableButton>
                </Tooltip>}
              {data?.type !== ScheduleTypes.INTERNAL && data?.wedding && <Tooltip title='Acesso Convidado'>
                <TableButton onClick={e => this.editGuestAccess(e, data)}>
                  <Icon type="solution" />
                </TableButton>
              </Tooltip>}
              {data?.type !== ScheduleTypes.INTERNAL && data?.wedding && data?.wedding?.status == WeddingStatus.CONFIRMED && data?.wedding?.meetingsBlocked
                && <Tooltip title='Desbloquear Reuniões'>
                  <TableButton onClick={e => this.unblockMeetings(data?.wedding)}>
                    <ReactSVG src={OpenLockIcon} />
                  </TableButton>
                </Tooltip>}
              {data?.confirmed && data?.type !== ScheduleTypes.INTERNAL && !data?.presence && now.isSameOrAfter(scheduleDate, 'day')
                && <Tooltip title='Registar Presença'>
                  <TableButton onClick={(e) => this.showPresenceModal(e, data)}>
                    <Icon type="carry-out" />
                  </TableButton>
                </Tooltip>}
              {!data?.presence && <Tooltip title='Editar'>
                <TableButton onClick={(e) => this.editSchedule(e, data)}>
                  <Icon type="edit" />
                </TableButton>
              </Tooltip>}
              {!data?.presence && <Tooltip title='Eliminar'>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={RemoveMessage}
                    okText='Eliminar'
                    onConfirm={async () => await this.deleteSchedule(data)}>
                    <Icon type="delete" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>}
            </div>
          };
        }
      }
    ],
    rows: [],

    colors: VenueColors,

    showModal: false,
    valuesModal: {},
    savingModal: false,

    showNotesModal: false,
    notesModal: {},
    savingNotes: false,

    showInternalMeetingModal: false,
    valuesInternalMeetingModal: {},
    savingInternalMeetingModal: false,

    showGuestAccessModal: false,
    guestWeddingId: null,

    showPresenceModal: false,
    valuesPresenceModal: {},
    markAsPresent: false,
    markAsMissed: false,
  };

  componentDidMount = async () => {
    this.setState({
      loading: this.props.loading,
      rows: this.props.rows,
      colors: this.props.colors,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
        rows: this.props.rows,
      });
    }
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  }

  // Schedule Notes
  editScheduleNotes = async ($event, data) => {
    $event.stopPropagation();

    this.setState({
      notesModal: {
        wedding: data?.wedding,
        meetings: data?.wedding?.meetings,
      },
      showNotesModal: true
    });
  }

  onNotesModalSubmit = async (values) => {
    const { notesModal, rows } = this.state;
    const weddingId = values?.wedding?._id || values?.wedding;

    try {
      this.setState({ savingNotes: true });

      const dataToUpdate = {
        meetings: values.meetings,
      }
      const { success, data } = await UpdateReserveNotes(weddingId, dataToUpdate);

      if (success && data) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });

        this.setState({
          showNotesModal: false,
          notesModal: {},
          savingNotes: false
        }, () => {
          this.props.onChange()
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingNotes: false });
    }
  };

  editSchedule = ($event, schedule) => {
    $event.stopPropagation();

    if (schedule?.type === ScheduleTypes.INTERNAL) {
      this.setState({
        showInternalMeetingModal: true,
        valuesInternalMeetingModal: schedule
      });
    } else {
      this.setState({
        showModal: true,
        valuesModal: schedule
      });
    }
  }

  submitSchedule = async (values) => {
    this.setState({ savingModal: true });

    const data = { ...values };
    if (values?.startTime && moment.utc(values?.startTime).isValid()) {
      data.startTime = moment.utc(data?.startTime).format('HH:mm');
    }
    const result = await UpdateInternalSchedule(data?._id, data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reunião atualizada!'
      });
      this.setState({
        showModal: false,
        showNotesModal: false,
        valuesModal: null,
        savingModal: false
      }, () => this.props.onChange());
    } else {
      this.setState({ savingModal: false });
    }
  }

  submitInternalSchedule = async (values) => {
    this.setState({ savingInternalMeetingModal: true });

    const data = { ...values };
    if (moment.utc(data?.startTime).isValid()) {
      data.startTime = moment.utc(data?.startTime).format('HH:mm');
    }
    if (moment.utc(data?.endTime).isValid()) {
      data.endTime = moment.utc(data?.endTime).format('HH:mm');
    }

    const result = await UpdateInternalSchedule(data?._id, data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Reunião interna atualizada!'
      });
      this.setState({
        showInternalMeetingModal: false,
        savingInternalMeetingModal: false
      }, () => this.props.onChange());
    } else {
      this.setState({ savingInternalMeetingModal: false });
    }
  }

  showPresenceModal = ($event, schedule) => {
    $event.stopPropagation();

    this.setState({
      showPresenceModal: true,
      valuesPresenceModal: schedule
    });
  }

  registerCouplePresence = async (presenceType) => {
    const { valuesPresenceModal } = this.state;

    this.setState({
      markAsPresent: presenceType === PresenceStatus.PRESENT ? true : false,
      markAsMissed: presenceType === PresenceStatus.MISSED ? true : false
    });

    const result = await RegisterSchedulePresence(valuesPresenceModal?._id, presenceType);

    if (result?.success) {
      const message = result?.data?.presence == PresenceStatus.PRESENT
        ? 'Registada a presença do casal'
        : result?.data?.presence == PresenceStatus.MISSED
          ? 'Registada a falta do casal'
          : 'Informação registada';
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: message
      });

      this.setState({
        showPresenceModal: false,
        markAsPresent: false,
        markAsMissed: false,
        valuesPresenceModal: null
      });

      this.props.onChange();
    } else this.setState({
      markAsPresent: false,
      markAsMissed: false,
    });
  }

  deleteSchedule = async (schedule) => {
    if (isCurrentUserEmployeeAttending()) return;

    const result = schedule?.type === ScheduleTypes.INTERNAL
      ? await SoftDeleteInternalSchedule(schedule?._id, true)
      : await SoftDeleteSchedule(schedule?._id, true);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: schedule?.type === ScheduleTypes.INTERNAL
          ? 'Reunião interna eliminada!'
          : 'Reunião eliminada!'
      });
      this.props.onChange();
    }
  }

  openProcess = record => {
    const { history } = this.props;

    if (isCurrentUserAdmin() && record?.wedding?.status !== WeddingStatus.RESERVE) {
      //Clear previous saved Wedding
      ClearWedding();
      history.push(`/wedding/${record?.wedding?._id}?page=S`);
    } else {
      return;
    }
  };

  editGuestAccess = (event, record) => {
    event.stopPropagation();
    this.setState({ showGuestAccessModal: true, guestWeddingId: record.wedding._id });
  };

  unblockMeetings = async (wedding) => {
    const res = await UnblockMeetings(wedding?._id);
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Successo',
        text: 'Reuniões desbloqueadas!'
      })
      this.props.onChange();
    }
  }

  editGeneral = (event, record) => {
    event.stopPropagation();
    const { history } = this.props;

    if (record?.wedding?.status === WeddingStatus.ARCHIVED) {
      if (isCurrentUserAdmin()) history.push(`/archived/wedding/${record?.wedding?._id}?page=MS`);
      else return;
    } else if (record?.wedding?.status !== WeddingStatus.RESERVE) {
      if (isCurrentUserAdmin()) history.push(`/weddings/${record?.wedding?._id}?page=MS`);
      else return;
    } else {
      const valuesReserveModal = record?.wedding ? {
        ...record.wedding,
        bridename: record.wedding.bride.name,
        brideemail: record.wedding.bride.email,
        bridecontact: record.wedding.bride.contact,
        groomname: record.wedding.groom.name,
        groomemail: record.wedding.groom.email,
        groomcontact: record.wedding.groom.contact,
        alternativename: record.wedding.alternativeGuest?.name || '',
        alternativemail: record.wedding.alternativeGuest?.email || '',
        alternativecontact: record.wedding.alternativeGuest?.contact || '',
        alternativerelationcouple: record.wedding.alternativeGuest?.relationToCouple || '',
      } : {};

      // Check employee attending
      if (isCurrentUserEmployeeAttending() && record?.wedding?.attendedBy) {
        const attendedById = record?.wedding?.attendedBy?._id ? record?.wedding?.attendedBy?._id : record?.wedding?.attendedBy;
        if (attendedById != getUserId()) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'Acesso não autorizado!'
          });
          return;
        }

        if (attendedById === getUserId() && record?.wedding?.attendingAt && moment.utc().isAfter(moment.utc(record?.wedding?.attendingAt).add(15, 'minutes'))) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'O período de edição terminou!'
          });
          return;
        }
      }

      this.setState({ showReserveModal: true, valuesReserveModal });
    }
  };

  updateWedding = async (values) => {
    const data = setDataToUpdateForReserveWedding(values);
    const result = await UpdateReserveRelatedInfo(values._id, data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Informação guardada'
      });
      this.setState({
        showReserveModal: false,
        valuesReserveModal: {}
      }, () => {
        this.props.onChange();
      });
    }
  }

  render() {
    const { history } = this.props;
    const { columns, rows, total, loading } = this.state;
    const { showGuestAccessModal, guestWeddingId } = this.state;
    const { showModal, valuesModal, savingModal } = this.state;
    const { showNotesModal, notesModal, savingNotes } = this.state;
    const { showPresenceModal, valuesPresenceModal, markAsPresent, markAsMissed } = this.state;
    const { showInternalMeetingModal, valuesInternalMeetingModal, savingInternalMeetingModal } = this.state;
    const { colors } = this.state;

    return <React.Fragment>
      <Table
        columns={columns}
        loading={loading}
        rows={rows}
        showHeader={true}
        // scroll={{ x: 1700 }}
        emptyIcon="calendar"
        emptyText={'Não existem reuniões'}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.openProcess}
      />

      {showModal
        && <MeetingModal
          isOpen={showModal}
          saving={savingModal}
          initialValues={valuesModal}
          onClose={() => this.setState({ showModal: false, valuesModal: null })}
          onSubmit={(values) => this.submitSchedule(values)}
        />}

      {showInternalMeetingModal
        && <ScheduleInternalModal
          isOpen={showInternalMeetingModal}
          saving={savingInternalMeetingModal}
          initialValues={valuesInternalMeetingModal}
          onClose={() => this.setState({ showInternalMeetingModal: false, valuesInternalMeetingModal: null })}
          onSubmit={(values) => this.submitInternalSchedule(values)}
        />}

      {showPresenceModal
        && <SchedulePresenceModal
          isOpen={showPresenceModal}
          markAsPresent={markAsPresent}
          markAsMissed={markAsMissed}
          initialValues={valuesPresenceModal}
          onClose={() => this.setState({ showPresenceModal: false, valuesPresenceModal: null })}
          onMarkAsPresent={(type) => this.registerCouplePresence(type)}
          onMarkAsMissed={(type) => this.registerCouplePresence(type)}
        />}

      {showGuestAccessModal
        && <AccessGuestModal
          isOpen={showGuestAccessModal}
          onClose={() => this.setState({ showGuestAccessModal: false, guestWeddingId: null })}
          weddingId={guestWeddingId}
        />}

      {showNotesModal
        && <ScheduleNotesModal
          isOpen={showNotesModal}
          saving={savingNotes}
          initialValues={notesModal}
          onSubmit={(values) => this.onNotesModalSubmit(values)}
          onClose={() => this.setState({ showNotesModal: false, notesModal: null })} />}
    </React.Fragment>;
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(MeetingsPageDetail);
