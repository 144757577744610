import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import DateInput from '../../components/inputs/DateInput';
import { getOrganizers } from '../../infra/requests/AdminsRequests';
import { isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import YearInput from '../../components/inputs/YearInput';
import XpertGoRangePickerInput from '../../components/inputs/XpertGoRangePickerInput';
import BaseButton from '../../components/buttons/BaseButton';
import XpertGoDateInput from '../../components/inputs/XpertGoDateInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import moment from 'moment';
import { GetMeetingOrganizers, GetScheduleColaborators, GetVisitColaborators } from '../../infra/requests/SchedulesRequests';
import { GetOrganizerList } from '../../infra/requests/UsersRequests';
import { ScheduleTypes } from './utilSchedules';

const visitStatus = [
  {
    _id: 1,
    name: 'Por Confirmar',
    value: 'TC'
  },
  {
    _id: 2,
    name: 'Confirmado',
    value: 'C'
  },
  {
    _id: 3,
    name: 'Confirmado s/presenças',
    value: 'CWP'
  },
  {
    _id: 4,
    name: 'Presentes',
    value: 'P'
  },
  {
    _id: 5,
    name: 'Faltaram',
    value: 'M'
  },
];

const meetingStatus = [
  {
    _id: 1,
    name: 'Confirmado',
    value: 'C'
  },
  {
    _id: 2,
    name: 'Confirmado s/presenças',
    value: 'CWP'
  },
  {
    _id: 3,
    name: 'Presentes',
    value: 'P'
  },
  {
    _id: 4,
    name: 'Faltaram',
    value: 'M'
  },
];

const scheduleTypes = [
  // {
  //   _id: ScheduleTypes.FIRST_VISIT,
  //   name: 'Visita',
  // },
  {
    _id: ScheduleTypes.ORGANIZATION,
    name: 'Organização',
  },
  {
    _id: ScheduleTypes.INTERNAL,
    name: 'Reunião Interna',
  },
];

const solar = 'Solar da Levada';
const lago = 'Quinta Lago dos Cisnes';
export const visitedLocals = [
  {
    _id: 1,
    name: solar
  },
  {
    _id: 2,
    name: lago
  },
  {
    _id: 3,
    name: 'Ambos os espaços'
  }
];

class SchedulesFilters extends Component {
  timeout = 0;
  state = {
    organizerMeetings: false,
    search: '',
    attendedBys: '',
    status: '',
    weddingPlace: '',
    date: '',
    dates: [],
    year: null,
    type: null,
    employees: [],
    loading: false
  };

  componentDidMount = async () => {
    const { loading, organizerMeetings } = this.props;
    this.setState({
      loading,
      organizerMeetings
    }, () => {
      this.getEmployees();
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }

    if (prevProps.organizerMeetings !== this.props.organizerMeetings) {
      this.setState({ organizerMeetings: this.props.organizerMeetings }, () => {
        this.getEmployees();
      });
    }
  }

  getEmployees = async () => {
    const { organizerMeetings } = this.state;
    const { data } = organizerMeetings
      ? await GetMeetingOrganizers()
      : await GetVisitColaborators();
    this.setState({ employees: data || [] });
  }

  costumeEmployees = (value) => {
    const userType = value?.employeeAttending
      ? ' - CA'
      : value?.organizer
        ? ' - Organizador'
        : value?.admin
          ? ' - Admin'
          : '';
    return `${value?.name || ''}${userType} ${value?.deleted ? '(Eliminado)' : ''}`
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (key !== 'employees'
        && key !== 'organizerMeetings'
        && key !== 'loading'
        && this.state[key]
        && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(query);
  };

  render() {
    const { loading, organizerMeetings } = this.state;
    const { employees } = this.state;
    const { search, weddingPlace, date, dates, year, status, type, attendedBys } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 300)
              }}
              meta={{ valid: true }}
              type="text"
              label="Pesquisar"
              placeholder="Procurar por nomes ou emails"
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={3}>
            <XpertGoDateInput
              label="Data"
              disabled={loading}
              placeholder="Escolher data"
              input={{
                value: date,
                onChange: value => {
                  this.onInputChange('date', value);
                  this.onInputChange('dates', []);
                  this.onInputChange('year', null);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} md={6}>
            <XpertGoRangePickerInput
              label="Intervalo de datas"
              allowClear={true}
              placeholder="Escolher datas"
              input={{
                value: dates,
                onChange: value => {
                  this.onInputChange('dates', value);
                  this.onInputChange('date', '');
                  this.onInputChange('year', null);
                }
              }}
              meta={{ valid: true }}
              disabled={loading}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={3} xl={2}>
            <YearInput
              label="Ano"
              disabled={loading}
              placeholder="Escolher ano"
              input={{
                value: year,
                onChange: value => {
                  this.onInputChange('dates', []);
                  this.onInputChange('date', '');
                  this.onInputChange('year', value);
                }
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Local"
              disabled={loading}
              allowClear
              placeholder="Escolher local"
              data={visitedLocals}
              input={{
                value: weddingPlace,
                onChange: value => this.onInputChange('weddingPlace', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Estado"
              disabled={loading}
              allowClear
              placeholder="Escolher estado"
              data={organizerMeetings ? meetingStatus : visitStatus}
              dataKey={'value'}
              input={{
                value: status,
                onChange: value => this.onInputChange('status', value)
              }}
              meta={{ valid: true }}
            />
          </Col>
          {organizerMeetings && <Col xs={24} sm={12} md={5} lg={4} xl={4}>
            <SelectInput
              label="Tipo"
              disabled={loading}
              allowClear
              placeholder="Escolher tipo"
              data={scheduleTypes}
              input={{
                value: type,
                onChange: value => this.onInputChange('type', value)
              }}
              meta={{ valid: true }}
            />
          </Col>}
          <React.Fragment>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <SelectInput
                label="Colaborador"
                disabled={loading}
                allowClear
                mode="multiple"
                placeholder="Escolher colaboradores"
                data={employees}
                costumeLabel={this.costumeEmployees}
                input={{
                  value: attendedBys,
                  onChange: value => {
                    if (value && Array.isArray(value) && value.length > 0) {
                      this.setState({ attendedBys: value });
                    } else {
                      this.onInputChange('attendedBys', value);
                    }
                  }
                }}
                meta={{ valid: true }}
              />
            </Col>
            <Col xs={24} sm={12} md={5} lg={1} xl={1} style={{ height: 58, display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
              <BaseButton
                type={'primary'}
                icon={'filter'}
                text={'Filtrar'}
                disabled={loading}
                onClick={() => this.onInputChange('attendedBys', attendedBys)}
              />
            </Col>
          </React.Fragment>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  locals: state.locals
});

export default connect(mapStateToProps)(SchedulesFilters);
