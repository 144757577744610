import React, { Component, Fragment } from 'react';
import { Icon, Menu, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import { TableButton } from '../../styles/BasicStyles';
import moment from 'moment';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { VenueColors, getVenueColor, getWeddingContacts, getWeddingEmails, getWeddingName, getWeddingStatus } from '../../infra/services/wedding/weddingUtils';
import { getUserId, isCurrentUserAdmin, isCurrentUserEmployeeAttending } from '../../infra/helpers/Helpers';
import { CheckMngMultipleByAttending, UpdateReserveRelatedInfo } from '../../infra/requests/ReserveRequests';
import Alerts from '../../components/alert/Alert';
import { GetTextColor } from '../../infra/services/text/textUtils';
import momentTimezone from 'moment-timezone';
import { AuthGoogleDrive } from '../../infra/requests/ReportsRequests';
import { ExtendReserveEditingTime, UnblockMeetings, UpdateReserveNotes } from '../../infra/requests/WeddingRequests';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import { ConfirmSchedule, DownloadSchedules, GetSchedulesPaginated, RegisterSchedulePresence, SoftDeleteInternalSchedule, SoftDeleteSchedule, UpdateInternalSchedule, UpdateOrganizationSchedule, UpdateSchedule, UpdateScheduleNotes, UpdateSchedules, UploadSchedulesToDrive } from '../../infra/requests/SchedulesRequests';
import AccessGuestModal from '../weddings/AccessGuestModal';
import { LocalLabel } from '../weddings/Styles';
import { SpanContact } from '../weddings/WeddingsPage';
import ConfirmScheduleModal from './ConfirmScheduleModal';
import SchedulePresenceModal, { PresenceStatus } from './SchedulePresenceModal';
import ReactSVG from 'react-svg';
import { setDataToUpdateForReserveWedding } from '../reserves/reservesUtils';
import ScheduleNotesModal from './ScheduleNotesModal';
import { GetScheduleRowColor, GetScheduleWarningRowColor, ScheduleTypes } from './utilSchedules';
import { SpanNotes } from './ScheduleStyles';
import VisitModal from './VisitModal';

const { confirm } = Modal;

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover esta visita?</div>
    <div>
      Toda a informação desta visita será removida da plataforma.
    </div>
  </div>
);

class VisitsPageDetail extends Component {
  state = {
    loading: false,
    loadingChild: false,

    organizerMeetings: false,

    columnsAttending: [
      {
        title: 'Horário',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <div>
                {moment.utc(data.startDate).local().format('HH:mm')}
                < Icon type="swap-right" />
                {moment.utc(data.endDate).local().format('HH:mm')}
              </div>
          };
        }
      },
      {
        title: 'Local',
        width: '50px',
        render: data => {
          const local = getVenueColor(data, this.state.colors);
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: local
              ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
              : null
          };
        }
      },
      {
        title: 'Colaborador',
        width: '100px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.attendedBy
              ? data?.attendedBy?.name
              // : data?.attendedUsers && data?.attendedUsers?.length > 0
              //   ? data?.attendedUsers?.map(m => m?.name).join(', ')
              : null
          };
        }
      },
      {
        title: 'Noivos',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: getWeddingName(data.wedding)
          };
        }
      },
      {
        title: 'Idioma',
        width: '100px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.language == 'pt_en'
              ? 'Preferencialmente Inglês, mas pode ser em Português'
              : data?.language == 'en'
                ? 'Inglês'
                : 'Português'
          };
        }
      },
      {
        title: 'Tipo de Atendimento',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.attendingType?.name?.pt || ''
          };
        }
      },
      {
        title: 'Presença',
        width: '50px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.presence && data?.presence == 'P'
              ? <Tag color="green">Presente</Tag>
              : data?.presence && data?.presence == 'M'
                ? <Tag color="red">Faltou</Tag>
                : ''
          };
        }
      },
      {
        title: 'Notas',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(this.state.colors, data),
                color: GetTextColor(GetScheduleRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: (data?.wedding?.visits || '')
                }}
                onClick={($event) => this.editScheduleNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Notas Reserva',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.reserveNotes || ''
                }}
                onClick={($event) => this.editScheduleNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Ponto de Situação',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleWarningRowColor(data),
                color: GetTextColor(GetScheduleWarningRowColor(data))
              }
            },
            children: <SpanNotes
              dangerouslySetInnerHTML={{
                __html: data?.weddingSummary || ''
              }}>
            </SpanNotes>
          };
        }
      },
      {
        title: 'Acções',
        width: '70px',
        // fixed: 'right',
        render: data => {
          const now = momentTimezone().tz('Europe/Lisbon');
          const scheduleDate = momentTimezone(data.date).tz('Europe/Lisbon');
          const attendedById = data?.attendedBy?._id ? data?.attendedBy?._id : data?.attendedBy;

          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <div onClick={e => e.stopPropagation()}>
              {data?.wedding?.status === WeddingStatus.RESERVE &&
                <Tooltip title={'Editar Noivos/Info Geral'}>
                  <TableButton onClick={e => this.editGeneral(e, data)}>
                    <Icon type="team" />
                  </TableButton>
                </Tooltip>}
              {data?.confirmed && data?.type !== ScheduleTypes.INTERNAL && !data?.presence && now.isSame(scheduleDate, 'day')
                && <Tooltip title='Registar Presença'>
                  <TableButton onClick={(e) => this.showPresenceModal(e, data)}>
                    <Icon type="carry-out" />
                  </TableButton>
                </Tooltip>}
              {data?.presence === 'P' && data?.type !== ScheduleTypes.INTERNAL && data?.wedding?.status === WeddingStatus.RESERVE && attendedById === getUserId() && <Tooltip title='Agenda do Casal'>
                <TableButton onClick={() => this.editCoupleAgenda(data)}>
                  <Icon type="calendar" />
                </TableButton>
              </Tooltip>}
            </div>
          };
        }
      }
    ],
    columns: [
      {
        title: 'Horário',
        width: '50px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <div>
                {moment.utc(data.startDate).local().format('HH:mm')}
                < Icon type="swap-right" />
                {moment.utc(data.endDate).local().format('HH:mm')}
              </div>
          };
        }
      },
      {
        title: 'Local',
        width: '50px',
        render: data => {
          const local = getVenueColor(data, this.state.colors);
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: local
              ? <LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>
              : null
          };
        }
      },
      {
        title: 'Confirmado',
        width: '40px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.confirmed
              ? <Tag color="green">Sim</Tag>
              : <Tag color="red">Por Confirmar</Tag>
          };
        }
      },
      {
        title: 'Colaborador',
        width: '100px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.attendedBy
              ? data?.attendedBy?.name
              // : data?.attendedUsers && data?.attendedUsers?.length > 0
              //   ? data?.attendedUsers?.map(m => m?.name).join(', ')
              : null
          };
        }
      },
      {
        title: 'Noivos',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: getWeddingName(data.wedding)
          };
        }
      },
      {
        title: 'Email',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <Tooltip title={getWeddingEmails(data.wedding)}>
              <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
                {getWeddingEmails(data.wedding)}
              </SpanContact>
            </Tooltip>
          };
        }
      },
      {
        title: 'Tel',
        width: '60px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <Tooltip title={getWeddingContacts(data.wedding)}>
              <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
                {getWeddingContacts(data.wedding)}
              </SpanContact>
            </Tooltip>
          };
        }
      },
      {
        title: 'Idioma',
        width: '100px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.language == 'pt_en'
              ? 'Preferencialmente em Inglês, mas pode ser em Português'
              : data?.language == 'en'
                ? 'Inglês'
                : 'Português'
          };
        }
      },
      {
        title: 'Tipo de Atendimento',
        width: '80px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.attendingType?.name?.pt || ''
          };
        }
      },
      {
        title: 'Presença',
        width: '50px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: data?.presence && data?.presence == 'P'
              ? <Tag color="green">Presente</Tag>
              : data?.presence && data?.presence == 'M'
                ? <Tag color="red">Faltou</Tag>
                : ''
          };
        }
      },
      {
        title: 'Outras Sugestões',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <SpanNotes
              dangerouslySetInnerHTML={{
                __html: data?.others || ''
              }}>
            </SpanNotes>
          };
        }
      },
      {
        title: 'Notas',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(this.state.colors, data),
                color: GetTextColor(GetScheduleRowColor(this.state.colors, data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: (data?.wedding?.visits || '')
                }}
                onClick={($event) => this.editScheduleNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Notas Reserva',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <Tooltip title={'Editar'}>
              <SpanNotes
                dangerouslySetInnerHTML={{
                  __html: data?.wedding?.reserveNotes || ''
                }}
                onClick={($event) => this.editScheduleNotes($event, data)}>
              </SpanNotes>
            </Tooltip>
          };
        }
      },
      {
        title: 'Ponto de Situação',
        width: '150px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: GetScheduleWarningRowColor(data),
                color: GetTextColor(GetScheduleWarningRowColor(data))
              }
            },
            children: <SpanNotes
              dangerouslySetInnerHTML={{
                __html: data?.weddingSummary || ''
              }}>
            </SpanNotes>
          };
        }
      },
      {
        title: 'Acções',
        width: '70px',
        // fixed: 'right',
        render: data => {
          const now = momentTimezone().tz('Europe/Lisbon');
          const scheduleDate = momentTimezone(data.date).tz('Europe/Lisbon');

          return {
            props: {
              style: {
                backgroundColor: GetScheduleRowColor(data),
                color: GetTextColor(GetScheduleRowColor(data))
              }
            },
            children: <div onClick={e => e.stopPropagation()}>
              {data?.type !== ScheduleTypes.INTERNAL
                && (isCurrentUserAdmin() || (!isCurrentUserAdmin() && data?.wedding?.status == WeddingStatus.RESERVE))
                && <Tooltip title={data?.wedding?.status !== WeddingStatus.RESERVE ? 'Editar Acessos/Padrão' : 'Editar Noivos/Info Geral'}>
                  <TableButton onClick={e => this.editGeneral(e, data)}>
                    <Icon type="team" />
                  </TableButton>
                </Tooltip>}
              {data?.type !== ScheduleTypes.INTERNAL && data?.wedding && <Tooltip title='Acesso Convidado'>
                <TableButton onClick={e => this.editGuestAccess(e, data)}>
                  <Icon type="solution" />
                </TableButton>
              </Tooltip>}
              {data?.type !== ScheduleTypes.INTERNAL && data?.wedding?.attendedBy
                && <Tooltip title='Prolongar Tempo de Edição'>
                  <TableButton onClick={e => this.extendEditingTime(e, data)}>
                    <Icon type="clock-circle" />
                  </TableButton>
                </Tooltip>}
              {!data?.confirmed && <Tooltip title='Confirmar'>
                <TableButton onClick={(e) => this.confirmSchedule(e, data)}>
                  <Icon type="check-circle" />
                </TableButton>
              </Tooltip>}
              {data?.confirmed && data?.type !== ScheduleTypes.INTERNAL && !data?.presence && now.isSameOrAfter(scheduleDate, 'day')
                && <Tooltip title='Registar Presença'>
                  <TableButton onClick={(e) => this.showPresenceModal(e, data)}>
                    <Icon type="carry-out" />
                  </TableButton>
                </Tooltip>}
              {!data?.presence && <Tooltip title='Editar'>
                <TableButton onClick={(e) => this.editSchedule(e, data)}>
                  <Icon type="edit" />
                </TableButton>
              </Tooltip>}
              {data.type !== ScheduleTypes.INTERNAL && data?.wedding?.status === WeddingStatus.RESERVE
                && <Tooltip title='Agenda do Casal'>
                  <TableButton onClick={() => this.editCoupleAgenda(data)}>
                    <Icon type="calendar" />
                  </TableButton>
                </Tooltip>}
              {!data?.presence && <Tooltip title='Eliminar'>
                <TableButton onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={RemoveMessage}
                    okText='Eliminar'
                    onConfirm={async () => await this.deleteSchedule(data)}>
                    <Icon type="delete" />
                  </Popconfirm>
                </TableButton>
              </Tooltip>}
            </div>
          };
        }
      }
    ],
    rows: [],

    colors: VenueColors,

    employee: null,

    showModal: false,
    valuesModal: {},
    savingModal: false,

    showNotesModal: false,
    notesModal: {},
    savingNotes: false,

    showInternalMeetingModal: false,
    valuesInternalMeetingModal: {},
    savingInternalMeetingModal: false,

    showConfirmModal: false,
    valuesConfirmModal: {},
    confirmingModal: false,

    showGuestAccessModal: false,
    guestWeddingId: null,

    showPresenceModal: false,
    valuesPresenceModal: {},
    markAsPresent: false,
    markAsMissed: false,
  };

  componentDidMount = async () => {
    this.setState({
      loading: this.props.loading,
      rows: this.props.rows,
      employee: this.props.employee,
      colors: this.props.colors,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({
        loading: this.props.loading,
        rows: this.props.rows,
      });
    }
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  }

  // Schedule Notes
  editScheduleNotes = async ($event, data) => {
    $event.stopPropagation();

    this.setState({
      notesModal: {
        wedding: data?.wedding,
        notes: data?.wedding?.reserveNotes,
        visits: data?.wedding?.visits,
      },
      showNotesModal: true
    });
  }

  onNotesModalSubmit = async (values) => {
    const { notesModal, rows } = this.state;
    const weddingId = values?.wedding?._id || values?.wedding;
    if (isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckMngMultipleByAttending(weddingId);
      if (!resultCheck.success) {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição terminou!'
        });
      }
    }

    try {
      this.setState({ savingNotes: true });

      const dataToUpdate = {
        reserveNotes: values.notes,
        visits: values.visits,
      }
      const { success, data } = await UpdateReserveNotes(weddingId, dataToUpdate);

      if (success && data) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });

        this.setState({
          showNotesModal: false,
          notesModal: {},
          savingNotes: false
        }, () => {
          this.props.onChange()
        });
      } else {
        const message = data == 'TIME_CREATE_EDIT_ENDED'
          ? 'O período de edição terminou!'
          : data == 'NOT_AUTHORIZED'
            ? 'Não autorizado'
            : data;
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: message
        });
        this.setState({
          savingNotes: false,
          showNotesModal: data == 'TIME_CREATE_EDIT_ENDED' || data == 'NOT_AUTHORIZED' ? false : true
        });
        return;
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingNotes: false });
    }
  };

  confirmSchedule = ($event, schedule) => {
    $event.stopPropagation();
    if (isCurrentUserEmployeeAttending()) return;

    this.setState({
      showConfirmModal: true,
      valuesConfirmModal: schedule
    });
  }

  checkSubmitConfirmSchedule = async (values) => {
    if (isCurrentUserEmployeeAttending()) return;

    const selectedRow = values.find(f => f?.checked);
    if (!selectedRow) {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Selecione uma visita para confirmar!'
      });
    }

    confirm({
      title: 'Pretende confirmar a visita selecionada?',
      content: (<div>
        <p>Ao confirmar a visita, serão eliminados as restantes "sugestões" de visita por confirmar do casal.</p>
        <p>Será notificado o casal sobre a visita!</p>
      </div>
      ),
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: () => {
        this.submitConfirmSchedule(selectedRow);
      },
      onCancel: () => {
        this.setState({ showConfirmModal: false, valuesConfirmModal: null });
      },
    });
  }

  submitConfirmSchedule = async (schedule) => {
    if (isCurrentUserEmployeeAttending()) return;

    this.setState({ confirmingModal: true });

    const result = await ConfirmSchedule(schedule?._id);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Visita confirmada!'
      });
      this.setState({
        showConfirmModal: false,
        valuesConfirmModal: null,
        confirmingModal: true
      }, () => this.props.onChange());
    } else {
      this.setState({ confirmingModal: false });
    }
  }

  editSchedule = ($event, schedule) => {
    $event.stopPropagation();
    if (isCurrentUserEmployeeAttending()) {
      return;
    }

    this.setState({
      showModal: true,
      valuesModal: schedule
    });
  }

  submitSchedule = async (values) => {
    if (isCurrentUserEmployeeAttending()) {
      return;
    }

    this.setState({ savingModal: true });

    const data = { ...values };
    if (values?.startTime && moment.utc(values?.startTime).isValid()) {
      data.startTime = moment.utc(data?.startTime).format('HH:mm');
    }
    const result = await UpdateSchedule(data?._id, data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Visita atualizada!'
      });
      this.setState({
        showModal: false,
        showNotesModal: false,
        valuesModal: null,
        savingModal: false
      }, () => this.props.onChange());
    } else {
      this.setState({ savingModal: false });
    }
  }

  showPresenceModal = ($event, schedule) => {
    $event.stopPropagation();

    this.setState({
      showPresenceModal: true,
      valuesPresenceModal: schedule
    });
  }

  registerCouplePresence = async (presenceType) => {
    const { valuesPresenceModal } = this.state;

    this.setState({
      markAsPresent: presenceType === PresenceStatus.PRESENT ? true : false,
      markAsMissed: presenceType === PresenceStatus.MISSED ? true : false
    });

    const result = await RegisterSchedulePresence(valuesPresenceModal?._id, presenceType);

    if (result?.success) {
      const message = result?.data?.presence == PresenceStatus.PRESENT
        ? 'Registada a presença do casal'
        : result?.data?.presence == PresenceStatus.MISSED
          ? 'Registada a falta do casal'
          : 'Informação registada';
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: message
      });

      this.setState({
        showPresenceModal: false,
        markAsPresent: false,
        markAsMissed: false,
        valuesPresenceModal: null
      });

      this.props.onChange();
    } else this.setState({
      markAsPresent: false,
      markAsMissed: false,
    });
  }

  editCoupleAgenda = (schedule) => {
    const { history } = this.props;

    if (isCurrentUserEmployeeAttending() && schedule?.presence === 'P') {
      history.push(`/agenda?wedding=${schedule?.wedding?._id}&page=S`);
    } else history.push(`/reserves-agenda?wedding=${schedule?.wedding?._id}&page=S`);
  }

  deleteSchedule = async (schedule) => {
    if (isCurrentUserEmployeeAttending()) return;

    const result = await SoftDeleteSchedule(schedule?._id, true);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Visita eliminada!'
      });
      this.props.onChange();
    }
  }

  openProcess = record => {
    const { history } = this.props;

    if (isCurrentUserAdmin() && record?.wedding?.status !== WeddingStatus.RESERVE) {
      //Clear previous saved Wedding
      ClearWedding();
      history.push(`/wedding/${record?.wedding?._id}?page=VS`);
    } else return;
  };

  editGuestAccess = (event, record) => {
    event.stopPropagation();
    this.setState({ showGuestAccessModal: true, guestWeddingId: record.wedding._id });
  };

  editGeneral = (event, record) => {
    event.stopPropagation();
    const { history } = this.props;

    if (record?.wedding?.status === WeddingStatus.ARCHIVED) {
      if (isCurrentUserAdmin()) history.push(`/archived/wedding/${record?.wedding?._id}?page=VS`);
      else return;
    } else if (record?.wedding?.status !== WeddingStatus.RESERVE) {
      if (isCurrentUserAdmin()) history.push(`/weddings/${record?.wedding?._id}?page=VS`);
      else return;
    } else {
      const valuesReserveModal = record?.wedding ? {
        ...record.wedding,
        bridename: record.wedding.bride.name,
        brideemail: record.wedding.bride.email,
        bridecontact: record.wedding.bride.contact,
        groomname: record.wedding.groom.name,
        groomemail: record.wedding.groom.email,
        groomcontact: record.wedding.groom.contact,
        alternativename: record.wedding.alternativeGuest?.name || '',
        alternativemail: record.wedding.alternativeGuest?.email || '',
        alternativecontact: record.wedding.alternativeGuest?.contact || '',
        alternativerelationcouple: record.wedding.alternativeGuest?.relationToCouple || '',
      } : {};

      // Check employee attending
      if (isCurrentUserEmployeeAttending() && record?.wedding?.attendedBy) {
        const attendedById = record?.wedding?.attendedBy?._id ? record?.wedding?.attendedBy?._id : record?.wedding?.attendedBy;
        if (attendedById != getUserId()) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'Acesso não autorizado!'
          });
          return;
        }

        if (attendedById === getUserId() && record?.wedding?.attendingAt && moment.utc().isAfter(moment.utc(record?.wedding?.attendingAt).add(15, 'minutes'))) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'O período de edição terminou!'
          });
          return;
        }
      }

      this.setState({ showReserveModal: true, valuesReserveModal });
    }
  };

  extendEditingTime = async (event, record) => {
    event.stopPropagation();

    const result = await ExtendReserveEditingTime(record?.wedding?._id);
    if (result.success) {
      return Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Tempo de edição prolongado por mais 15 minutos!'
      });
    }
  };

  updateWedding = async (values) => {
    //Check if employee attending still has time to create/edit wedding reserves
    if (isCurrentUserEmployeeAttending()) {
      const resultCheck = await CheckMngMultipleByAttending(values._id);
      if (!resultCheck?.success) {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O período de edição terminou!'
        });
        this.setState({ showReserveModal: false, valuesReserveModal: {} });
        return;
      }
    }

    const data = setDataToUpdateForReserveWedding(values);
    const result = await UpdateReserveRelatedInfo(values._id, data);
    // console.warn('resultCoupleWedding', resultCoupleWedding);

    if (result.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: 'Informação guardada'
      });
      this.setState({
        showReserveModal: false,
        valuesReserveModal: {}
      }, () => {
        this.props.onChange();
      });
    }
  }

  render() {
    const { history } = this.props;
    const { columns, columnsAttending } = this.state;
    const { rows, loading } = this.state;
    const { showGuestAccessModal, guestWeddingId } = this.state;
    const { showModal, valuesModal, savingModal } = this.state;
    const { showNotesModal, notesModal, savingNotes } = this.state;
    const { showConfirmModal, valuesConfirmModal, confirmingModal } = this.state;
    const { showPresenceModal, valuesPresenceModal, markAsPresent, markAsMissed } = this.state;
    const { colors } = this.state;

    return <React.Fragment>
      <Table
        columns={isCurrentUserEmployeeAttending() ? columnsAttending : columns}
        loading={loading}
        rows={rows}
        showHeader={true}
        // scroll={{ x: 1700 }}
        emptyIcon="calendar"
        emptyText={'Não existem visitas'}
        rowKey={'_id'}
        hasPagination={false}
        onPressRow={this.openProcess}
      />

      {showModal
        && <VisitModal
          isOpen={showModal}
          saving={savingModal}
          confirming={confirmingModal}
          initialValues={valuesModal}
          onClose={() => this.setState({ showModal: false, valuesModal: null })}
          onSubmit={(values) => this.submitSchedule(values)}
        />}

      {showConfirmModal
        && <ConfirmScheduleModal
          isOpen={showConfirmModal}
          saving={confirmingModal}
          initialValues={valuesConfirmModal}
          onClose={() => this.setState({ showConfirmModal: false, valuesConfirmModal: null })}
          onSubmit={(values) => this.checkSubmitConfirmSchedule(values)}
          colors={colors}
        />}

      {showPresenceModal
        && <SchedulePresenceModal
          isOpen={showPresenceModal}
          markAsPresent={markAsPresent}
          markAsMissed={markAsMissed}
          initialValues={valuesPresenceModal}
          onClose={() => this.setState({ showPresenceModal: false, valuesPresenceModal: null })}
          onMarkAsPresent={(type) => this.registerCouplePresence(type)}
          onMarkAsMissed={(type) => this.registerCouplePresence(type)}
        />}

      {showGuestAccessModal
        && <AccessGuestModal
          isOpen={showGuestAccessModal}
          onClose={() => this.setState({ showGuestAccessModal: false, guestWeddingId: null })}
          weddingId={guestWeddingId}
        />}

      {showNotesModal
        && <ScheduleNotesModal
          isOpen={showNotesModal}
          saving={savingNotes}
          initialValues={notesModal}
          onSubmit={(values) => this.onNotesModalSubmit(values)}
          onClose={() => this.setState({ showNotesModal: false, notesModal: null })} />}
    </React.Fragment>;
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(VisitsPageDetail);
