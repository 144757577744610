import React, { Component, Fragment } from 'react';
import { Avatar, Button, Dropdown, Icon, Menu, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  GetWeddings,
  TogglePublic,
  DeleteWedding,
  SoftDeleteWedding,
  DownloadWeddings,
  UnblockMeetings,
  ConvertToVisitsAgenda,
  ConvertToMeetingsAgenda
} from '../../infra/requests/WeddingRequests';
import { ArchiveWedding } from '../../infra/requests/ArchivedRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import WeddingsFilters from './WeddingsFilters';
import { LocalLabel, StateLabel } from './Styles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { GetTeamBookingByWedding } from '../../infra/requests/TeamBookingRequests';
import { getInitials, getLocal, getLocalColor, getWeddingContacts, getWeddingEmails, getWeddingName } from '../../infra/services/wedding/weddingUtils';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import AvailableMapModal from './AvailableMapModal';
import ModalPDF from '../employees/ModalPDF';
import { PDFViewer } from '@react-pdf/renderer';
import ModalDeleteWedding from './DeleteWeddingModal';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { DateColumn, SpanAttended, SpanNotes } from '../reserves/ReservesPage';
import { GetRowColor, GetTagPaymentColor } from '../reserves/reservesUtils';
import WeddingModal from './WeddingModal';
import WeddingDetail from './WeddingDetail';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import styled, { createGlobalStyle } from 'styled-components';
import Alerts from '../../components/alert/Alert';
import { GetSettingsByType } from '../../infra/requests/SettingRequests';
import { UpdateWeddingDetails } from '../../infra/requests/WeddingProcessRequests';
import AccessGuestModal from './AccessGuestModal';
import { VisitBlockedTypes, WeddingStatus } from '../reserves/ReserveAgenda';
import OpenLockIcon from '../../assets/icons/open_lock.svg';
import SwitchAgendaIcon from '../../assets/icons/switch_agenda.svg';
import ReactSVG from 'react-svg';
import ExportWeddingsModal from './exportation/ExportWeddingsModal';
const { confirm } = Modal;

export const SpanContact = styled.a`
  text-overflow: ellipsis;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
`;

export const SpanArea = styled.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  overflow: auto;
`;

export const SpanGraphics = styled.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  overflow: auto;
  display: grid;
  align-items: flex-start;
  justify-content: center;

  .notes {
    display: flex;
    flex-direction: column;
  }

  .notes-title {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: underline;
  }
`;

const ArchiveMessage = (
  <div>
    <div>Tem a certeza que quer arquivar este casamento? </div>
    <div>Deixará de conseguir editar qualquer informação deste casamento.</div>
    <div>
      Os noivos serão notificados que o acesso à plataforma foi removido.
    </div>
  </div>
);

const RemoveMessage = (
  <div>
    <div>Tem a certeza que quer remover este casamento? </div>
    <div>
      Toda a informação deste casamento será removida da plataforma e não
      conseguirá ser recuperada.
    </div>
    <div>
      Os noivos não receberão qualquer notificação sobre o acesso à plataforma.
    </div>
  </div>
);
class WeddingsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,

    openModal: false,
    showPreviewPDF: false,
    availableMapPdf: null,

    openModalDelete: false,
    weddingToDelete: null,

    openWeddingModal: false,
    dataWeddingModal: null,

    filters: {},
    needRefresh: false,

    columns: [
      {
        title: 'Data',
        width: '80px',
        render: data => {
          return data?.wedding && data.wedding?.date ?
            <DateColumn>
              <span className='date'>{moment.utc(data.wedding.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data.wedding.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : ''
        }
      },
      {
        title: 'Local',
        width: '50px',
        render: value => {
          const local = getLocalColor(value?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>) : null
        }
      },
      {
        title: 'Noivos',
        width: '80px',
        className: 'align-center',
        render: data => {
          return <React.Fragment>
            {data?.wedding?.hidden && <Tag style={{ marginRight: 0, marginBottom: 4 }} color="red">Fictício</Tag>}
            <p>{getWeddingName(data.wedding)}</p>
          </React.Fragment>
        }
      },
      {
        title: 'Email',
        width: '60px',
        render: data => {
          return <Tooltip title={getWeddingEmails(data.wedding)}>
            <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
              {getWeddingEmails(data.wedding)}
            </SpanContact>
          </Tooltip>
        }
      },
      {
        title: 'Tel',
        width: '60px',
        render: data => {
          return <Tooltip title={getWeddingContacts(data.wedding)}>
            <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
              {getWeddingContacts(data.wedding)}
            </SpanContact>
          </Tooltip>
        }
      },
      {
        title: 'Preço p/PAX',
        width: '70px',
        render: value => <CurrencyComponent value={value.wedding.price} />
      },
      {
        title: 'Mínimo PAX',
        width: '55px',
        dataIndex: 'wedding.min_people'
      },
      {
        title: 'PAX Previsto',
        width: '65px',
        render: value => value?.ourGuest && value?.ourGuest?.provisory_guests ? value?.ourGuest?.provisory_guests : ''
      },
      // {
      //   title: 'Data Contrato',
      //   width: '85px',
      //   render: data => {
      //     return data?.wedding?.contractDate
      //       ? moment(data?.wedding?.contractDate).format('DD-MM-YYYY')
      //       : '';
      //   }
      // },
      {
        title: 'Idioma (Organizador)',
        width: '85px',
        render: data => {
          return data?.wedding?.organizerLanguage == 'pt'
            ? 'Português'
            : data?.wedding?.organizerLanguage == 'en'
              ? 'Inglês'
              : data?.wedding?.organizerLanguage == 'pt_en'
                ? 'Português e Inglês'
                : '';
        }
      },
      {
        title: 'Pago',
        width: '80px',
        render: data => {
          return <React.Fragment>
            <CurrencyComponent value={data?.budget && data?.budget?.payed ? data?.budget.payed : 0} />
            <div style={{ marginTop: 5 }}>
              {data?.payedPhase3 < 0
                ? <Tag color='red' key={data.id}>
                  {data?.budget?.payedPercentage !== null && data?.budget?.payedPercentage !== undefined ? (data?.budget?.payedPercentage).toFixed(2) : 0} %
                </Tag>
                : <span>{(data?.budget && data?.budget?.payedPercentage >= 0 ? data.budget.payedPercentage : 0).toFixed(2) + '%'}</span>
              }
            </div>
          </React.Fragment>
        }
      },
      {
        title: 'Notas Pagamento',
        width: '75px',
        render: data => {
          return <Tooltip title='Editar'>
            <SpanNotes
              dangerouslySetInnerHTML={{
                __html: data?.wedding?.paymentNotes || ''
              }}
              onClick={e => this.openInfoModal(e, data)}>
            </SpanNotes>
          </Tooltip>
        }
      },
      {
        title: 'Visível Decor',
        width: '55px',
        render: data => (
          <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            {data.wedding.public ? 'Sim' : 'Não'}
            <Tooltip title={data.wedding.public ? ' Esconder' : ' Publicar'}>
              <TableButton onClick={e => e.stopPropagation()} style={{ marginRight: 0 }}>
                <Popconfirm
                  placement="topRight"
                  title={
                    data.wedding.public
                      ? 'Quer remover este casamento da lista pública?'
                      : 'Quer tornar este casamento vísivel na lista pública?'
                  }
                  onConfirm={() => this.togglePublic(data.wedding._id)}>
                  <Icon type={data.wedding.public ? 'stop' : 'check-circle'} />
                </Popconfirm>
              </TableButton>
            </Tooltip>
          </div>
        )
      },
      {
        title: 'Notas Reserva',
        width: '70px',
        render: data => {
          return <Tooltip title='Editar'>
            <SpanNotes
              dangerouslySetInnerHTML={{
                __html: data?.wedding?.reserveNotes || ''
              }}
              onClick={e => this.openInfoModal(e, data)}>
            </SpanNotes>
          </Tooltip>
        }
      },
      {
        title: 'Provas',
        width: '70px',
        render: data => {
          return <Tooltip title='Editar'>
            <SpanArea
              dangerouslySetInnerHTML={{
                __html: data?.wedding?.proofs || ''
              }}
              onClick={e => this.openInfoModal(e, data)}>
            </SpanArea>
          </Tooltip>
        }
      },
      {
        title: 'Organizador',
        width: '50px',
        className: 'align-center',
        render: data => {
          return <SpanAttended onClick={($event) => this.openInfoModal($event, data)}>
            {data?.wedding?.organizer?.length > 0
              ? <Tooltip title={`Organizador(es): ${data?.wedding?.organizer?.map(m => m?.name).join(', ')}`}>
                {data?.wedding?.organizer?.map(m =>
                  <Avatar style={{ backgroundColor: '#e4ffdf', border: '1px solid black', color: 'black', margin: 3 }}>
                    {getInitials(m?.name)}
                  </Avatar>
                )}
              </Tooltip>
              : null}
          </SpanAttended>
        }
      },
      {
        title: 'Reuniões',
        width: '70px',
        render: data => {
          return <Tooltip title='Editar'>
            <SpanArea
              dangerouslySetInnerHTML={{
                __html: data?.wedding?.meetings || ''
              }}
              onClick={e => this.openInfoModal(e, data)}>
            </SpanArea>
          </Tooltip>
        }
      },
      {
        title: 'Grafismo',
        width: '70px',
        render: data => {
          return {
            props: {
              style: {
                backgroundColor: data?.wedding?.graphics?.graphicColor,
                color: GetTextColor(data?.wedding?.graphics?.graphicColor)
              }
            },
            children: <Tooltip title='Editar'>
              <SpanGraphics onClick={e => this.openInfoModal(e, data)}>
                <span>{data?.wedding && data?.wedding?.graphics ? data?.wedding?.graphics?.name : ''}</span>
                {data?.wedding?.graphicNotes && <div className="notes">
                  <span className="notes-title">Notas:</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.wedding?.graphicNotes || ''
                    }}
                    onClick={e => this.openInfoModal(e, data)}>
                  </div>
                </div>}
              </SpanGraphics>
            </Tooltip>
          };
        }
      },
      {
        title: 'Impresso',
        width: '60px',
        render: value => this.renderPrintedState(value.wedding.printed),
      },
      {
        title: 'Chegada à Quinta',
        width: '60px',
        render: value => {
          return value.ourDay ? value.ourDay.time : 'ND';
        }
      },
      {
        title: 'Estado Equipa',
        width: '80px',
        render: data => {
          const status = data.payments && data.payments && data.payments.length > 0 ? 5 :
            data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0 ? 4 :
              data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length === 0 ? 3 :
                data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 ? 2 :
                  data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length === 0 ? 1 : 0;

          return status === 1 ? 'Encomenda em Processo' : status === 2 ? 'Encomenda Publicada (RH)' :
            status === 3 ? 'Equipa em Constituição' : status === 4 ? 'Equipa Publicada' :
              status === 5 ? 'Pagamentos Gerados' : 'Sem Encomenda';
        }
      },
      {
        title: 'Acções',
        // fixed: 'right',
        width: '70px',
        render: data => (
          <div onClick={e => e.stopPropagation()}>
            <Tooltip title='Editar Acessos/Padrão'>
              <TableButton onClick={e => this.editAccess(e, data)}>
                <Icon type="edit" />
              </TableButton>
            </Tooltip>
            <Tooltip title='Acesso Convidado'>
              <TableButton onClick={e => this.editGuestAccess(e, data)}>
                <Icon type="solution" />
              </TableButton>
            </Tooltip>
            <Tooltip title={
              data?.wedding?.status == WeddingStatus.CONFIRMED && data?.wedding?.visitsBlocked == VisitBlockedTypes.COMPLETED
                ? 'Alterar para agenda de visitas'
                : 'Alterar para agenda de reuniões'
            }>
              <TableButton onClick={e => this.confirmChangeBetweenVisitAndMeetingAgenda(data.wedding)}>
                <Icon type="retweet" />
              </TableButton>
            </Tooltip>
            {data?.wedding?.status == WeddingStatus.CONFIRMED && data?.wedding?.meetingsBlocked
              && <Tooltip title='Desbloquear Reuniões'>
                <TableButton onClick={e => this.unblockMeetings(data.wedding)}>
                  <ReactSVG src={OpenLockIcon} />
                </TableButton>
              </Tooltip>}
            <Tooltip title='Encomenda'>
              <TableButton onClick={e => this.manageTeamBooking(e, data)}>
                <Icon type="inbox" />
              </TableButton>
            </Tooltip>
            <Tooltip title='Equipa'>
              <TableButton onClick={e => this.manageWeddingTeam(e, data)}>
                <Icon type="team" />
              </TableButton>
            </Tooltip>
            {!data?.wedding?.hidden && <Tooltip title='Arquivar'>
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title={ArchiveMessage}
                  okText="Arquivar"
                  onConfirm={async () => await this.archiveWedding(data.wedding._id)}>
                  <Icon type="database" />
                </Popconfirm>
              </TableButton>
            </Tooltip>}
            {!data?.wedding?.hidden && <Tooltip title='Remover'>
              <TableButton onClick={e => {
                e.stopPropagation();
                this.setState({ openModalDelete: true, weddingToDelete: data?.wedding });
              }}>
                <Icon type="delete" />
              </TableButton>
            </Tooltip>}
          </div>
        )
      }
    ],
    rows: [],
    total: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    },

    openInfoModal: false,
    infoModal: {},
    savingInfoModal: false,

    showGuestAccessModal: false,
    guestWeddingId: null,

    showExportModal: false,
  };

  componentDidMount = async () => {
    await this.updateDataTable();
    await this.getLocalColors();
  };

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  };

  openProcess = record => {
    const { history } = this.props;

    //Clear previous saved Wedding
    ClearWedding();
    history.push(`/wedding/${record.wedding._id}`);
  };

  editAccess = (event, record) => {
    event.stopPropagation();
    const { history } = this.props;
    history.push(`/weddings/${record.wedding._id}`);
  };

  editGuestAccess = (event, record) => {
    event.stopPropagation();
    this.setState({ showGuestAccessModal: true, guestWeddingId: record.wedding._id });
  };

  confirmChangeBetweenVisitAndMeetingAgenda = (wedding) => {
    const title = wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitBlockedTypes.COMPLETED
      ? 'Alterar para agenda de visitas'
      : 'Alterar para agenda de reuniões';

    const content = wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitBlockedTypes.COMPLETED
      ? 'O casal passará apenas a ter possibilidade de agendar visitas.'
      : 'O casal passará apenas a ter possibilidade de agendar reuniões com os organizadores.';

    confirm({
      title,
      content,
      okText: 'Alterar agenda',
      okType: 'primary',
      cancelText: 'Cancelar',
      onOk: () => {
        return this.changeBetweenVisitAndMeetingAgenda(wedding);
      },
      onCancel() { },
    });
  }

  changeBetweenVisitAndMeetingAgenda = async (wedding) => {
    const changeToVisitAgenda = wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitBlockedTypes.COMPLETED
      ? true
      : false;

    const result = changeToVisitAgenda
      ? await ConvertToVisitsAgenda(wedding?._id)
      : await ConvertToMeetingsAgenda(wedding?._id);

    if (result?.success) {
      const successMsg = changeToVisitAgenda
        ? 'Alterado para agenda de visitas com sucesso!'
        : 'Alterado para agenda de reuniões com sucesso!';
      Alerts.new({
        type: 'success',
        title: 'Successo',
        text: successMsg
      })
      this.updateDataTable();
    }
  }

  unblockMeetings = async (wedding) => {
    const res = await UnblockMeetings(wedding?._id);
    if (res?.success) {
      Alerts.new({
        type: 'success',
        title: 'Successo',
        text: 'Reuniões desbloqueadas!'
      })
      this.updateDataTable();
    }
  }

  manageTeamBooking = (e, data) => {
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/team-booking/${data.wedding._id}`);
  }

  manageWeddingTeam = (e, data) => {
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/wedding-team/${data.wedding._id}`);
  }

  deleteWedding = async (wedding, password, notify) => {
    const weddingDate = moment.utc(wedding?.date);
    if (weddingDate.isBefore(moment.utc(), 'date')) {
      const res = await SoftDeleteWedding(wedding?._id, true, false, JSON.parse(localStorage.user).email, password);
      if (res?.success) this.updateDataTable();
      else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: res?.data
        });
      }
    } else {
      const res = await SoftDeleteWedding(wedding?._id, true, notify, JSON.parse(localStorage.user).email, password);
      if (res?.success) this.updateDataTable();
      else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: res?.data
        });
      }
    }
  };

  archiveWedding = async wedding => {
    this.setState({ loading: true })
    const result = await ArchiveWedding({ wedding });
    if (!result.success) console.error(result.message);
    return this.updateDataTable();
  };

  togglePublic = async id => {
    await TogglePublic(id);
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    if (this.state.loading) {
      this.setState({ needRefresh: true });
      return;
    }

    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;

      if (Array.isArray(filters?.dates) && filters?.dates.length === 0) {
        delete filters.dates;
      }

      const result = await GetWeddings(currentPage, pageSize, JSON.stringify(filters));
      // console.warn('Res', result);

      this.setState({
        rows: result?.data && result.data.items ? result.data.items : [],
        total: result?.data && result.data.total ? result.data.total : 0,
        //currentPage: result?.data && result.data.page ? result.data.page : 1,
        pageSize: result?.data && result.data.limit ? result.data.limit : 30,
        loading: false,
      }, () => {
        if (this.state.needRefresh) {
          this.setState({ needRefresh: false }, () => this.updateDataTable());
        }
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderPrintedState = (value) => {
    if (value) {
      return (
        <StateLabel printed={true}>Sim</StateLabel>
      );
    }
    return (
      <StateLabel printed={false}>Não</StateLabel>
    );
  };

  openInfoModal = async ($event, row) => {
    $event.stopPropagation();

    this.setState({
      infoModal: {
        wedding: row?.wedding,
        paymentNotes: row?.wedding?.paymentNotes,
        reserveNotes: row?.wedding?.reserveNotes,
        environments: row?.wedding?.environments,
        proofs: row?.wedding?.proofs,
        meetings: row?.wedding?.meetings,
        graphics: row?.wedding?.graphics && row?.wedding?.graphics?._id ? row?.wedding?.graphics?._id : row?.wedding?.graphics,
        graphicNotes: row?.wedding?.graphicNotes,
        organizer: row?.wedding?.organizer?.map(m => m?._id),
      },
      openInfoModal: true,
    });
  };

  onInfoModalSubmit = async values => {
    const { infoModal, rows } = this.state;
    try {
      this.setState({ savingInfoModal: true });

      const { success, data } = await UpdateWeddingDetails(infoModal?.wedding?._id, values);

      if (success && data) {
        Alerts.new({
          type: 'success',
          title: 'Gravado com sucesso',
          text: 'A informação foi gravada com sucesso!'
        });

        const index = rows.findIndex(f => f?.wedding && f?.wedding?._id && data?._id && f?.wedding?._id === data?._id);
        if (index > -1) {
          rows[index].wedding = data;
          this.setState({ rows });
        }

        this.setState({ openInfoModal: false, infoModal: {}, savingInfoModal: false });
      } else {
        this.setState({ savingInfoModal: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ savingInfoModal: false });
    }
  };

  render() {
    const { history } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { openModal, showPreviewPDF } = this.state;
    const { openModalDelete, weddingToDelete } = this.state;
    const { openWeddingModal, dataWeddingModal } = this.state;
    const { colors } = this.state;
    const { loadingInfoModal, openInfoModal, infoModal, savingInfoModal } = this.state;
    const { showGuestAccessModal, guestWeddingId } = this.state;
    const { showExportModal, filters } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={3}>
            <PageTitle>Casamentos</PageTitle>
            Lista de Casamentos
          </HeaderTitle>
          <HeaderButtonsContainer buttons={3}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={'Novo Casamento'}
              onClick={() => history.push('/weddings/add')}
            />
            <BaseButton
              type={'primary'}
              icon={'carry-out'}
              text={'Mapa Disponibilidade'}
              onClick={() => this.setState({ openModal: true })}
            />
            <BaseButton
              type={'primary'}
              icon={'file-protect'}
              text={'Exportar'}
              onClick={() => this.setState({ showExportModal: true })}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={3}>
          <WeddingsFilters loading={loading} queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            scroll={{ x: 1300 }}
            emptyIcon="team"
            emptyText={'There are no weddings'}
            total={total}
            rowKey={'wedding._id'}
            hasPagination={true}
            onPressRow={this.openProcess}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            defaultExpandAllRows={false}
            expandedRowRender={(data) => <WeddingDetail wedding={data?.wedding} colors={colors} onChange={(values) => this.editWedding(values)} />}
          />
        </PageContainer>

        {openModal && <AvailableMapModal
          openModal={openModal}
          onCancel={() => this.setState({ openModal: false })}
          showPdf={(pdf) => this.setState({ openModal: false, availableMapPdf: pdf, showPreviewPDF: true })}
        />}

        <Modal
          visible={this.state.showPreviewPDF}
          title={'Mapa de Disponibilidade'}
          width={800} maskClosable
          onCancel={() => this.setState({ showPreviewPDF: false })}
          footer={[<Button key='cancel' type='primary' onClick={() => () => this.setState({ showPreviewPDF: false })}>OK</Button>]}>
          <PDFViewer style={{ width: '100%', height: '390px', margin: '-24px 0' }}>
            {this.state.availableMapPdf}
          </PDFViewer>
        </Modal>

        {openModalDelete && <ModalDeleteWedding
          isOpen={openModalDelete}
          initialValues={{ title: RemoveMessage, wedding: weddingToDelete }}
          onSubmit={($event) => this.setState({ openModalDelete: false, weddingToDelete: null }, async () => await this.deleteWedding($event.wedding, $event.password, $event.notify))}
          onClose={() => this.setState({ openModalDelete: false, weddingToDelete: null })}
        />}

        {openInfoModal &&
          <WeddingModal
            isOpen={openInfoModal}
            onSubmit={this.onInfoModalSubmit}
            saving={savingInfoModal}
            onClose={() => this.setState({ openInfoModal: false, infoModal: {}, savingInfoModal: false })}
            initialValues={infoModal}
            onAddGraphic={(form) => this.setState({ infoModal: { wedding: infoModal?.wedding, ...form } })}
          />}

        {showGuestAccessModal && <AccessGuestModal
          isOpen={showGuestAccessModal}
          onClose={() => this.setState({ showGuestAccessModal: false, guestWeddingId: null })}
          weddingId={guestWeddingId}
        />}

        {showExportModal && <ExportWeddingsModal
          isOpen={showExportModal}
          initialValues={filters}
          onClose={() => this.setState({ showExportModal: false })}
        />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(WeddingsPage);
