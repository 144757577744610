import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment, change, useReducer, useCallback } from 'react';
import { Icon, Input } from 'antd';
import { DeleteSeatButton, SeatAge, SeatFoodRestrict, SeatName, SeatRow, StatsContent, StatsContentRow, StatsTotal, TableGuests, TableGuestsHeader, TableGuestsOptions, TableGuestsStats, TableName, TableSeatCode } from '../../process/guests/RoomPlanStyles';
import SelectAgeInput from '../inputs/SelectAgeInput';
import SelectRestrictionInput from '../inputs/SelectRestrictionInput';
import { GuestAgeEnum, RoomPlanAgeTypes, TablePurposeEnum, setRestrictionsBasedOnAge } from './utilGuest';

const GuestSeatComponent = ({
  index,
  seat,
  table,
  foodRestrictions,
  parentUpdateSeat,
  parentRemoveSeat
}) => {
  const [tableObj, setTable] = useState(table);

  const [number, setNumber] = useState(seat?.number);
  const [guestName, setGuestName] = useState(seat?.guestName);
  const [guestAge, setGuestAge] = useState(seat?.guestAge);
  const [guestRestrictions, setGuestRestrictions] = useState(seat?.foodRestrictions);
  const [isCouple, setIsCouple] = useState(seat.couple);
  const [frontCouple, setFrontCouple] = useState(seat.frontCouple);

  const [disabled, setDisabled] = useState(false);
  const [isStaff, setIsStaff] = useState(false);

  useEffect(() => {
    setNumber(seat?.number);
    setGuestName(seat?.guestName);
    setGuestAge(seat?.guestAge);
    setGuestRestrictions(seat?.foodRestrictions);
    setIsCouple(seat?.couple);
    setFrontCouple(seat?.frontCouple);
  }, [seat, seat?.guestName, seat?.guestAge, seat?.couple, seat?.frontCouple, seat?.foodRestrictions]);

  useEffect(() => {
    setTable(table);
    setDisabled(!table?.active);
    setIsStaff(table?.tablePurpose == TablePurposeEnum.STAFF);
  }, [table]);

  const getSeatCode = () => {
    return (number || 0) + 1;
  }

  const removeSeat = () => {
    if (disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    if (isStaff) {
      parentRemoveSeat(index);
    } else {
      setGuestName('');
      setGuestAge(GuestAgeEnum.ADULT);
      setGuestRestrictions([]);
      parentUpdateSeat('UPDATE_SEAT', index, {
        number,
        couple: isCouple,
        frontCouple,
        guestName: '',
        guestAge: GuestAgeEnum.ADULT,
        foodRestrictions: []
      });
    }
  }

  const handleNameChange = (event) => {
    if (disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    setGuestName(event.target.value);
    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName: event.target.value,
      guestAge,
      foodRestrictions: guestRestrictions
    });
  };

  const handleAgeChange = (value) => {
    if (disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    const originalGuestAge = guestAge;
    const restrictionsToUpdate = setRestrictionsBasedOnAge(originalGuestAge, value, guestRestrictions, foodRestrictions);
    setGuestAge(value);
    setGuestRestrictions(restrictionsToUpdate);

    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName,
      guestAge: value,
      foodRestrictions: restrictionsToUpdate
    });
  }

  const handleChangeRestriction = (values) => {
    if (disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    setGuestRestrictions(values);
    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName,
      guestAge,
      foodRestrictions: values
    });
  }

  return (
    <SeatRow key={index} disabled={disabled}>
      <TableSeatCode couple={isCouple} seat={true}>
        {getSeatCode() || 'X'}
      </TableSeatCode>

      <SeatName>
        <Input
          type="text"
          style={{ color: guestName?.trim() != '' ? 'black' : 'inherit' }}
          // placeholder='Nome'
          disabled={disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          value={guestName}
          onChange={handleNameChange} />
      </SeatName>
      <SeatAge staff={isStaff}>
        <SelectAgeInput
          placeholder={'Idade'}
          meta={{ invalid: false, submitFailed: false }}
          data={RoomPlanAgeTypes}
          disabled={disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          input={{
            value: guestAge,
            onChange: handleAgeChange
          }} />
      </SeatAge>
      <SeatFoodRestrict staff={isStaff}>
        <SelectRestrictionInput
          placeholder={'Alergias'}
          meta={{ invalid: false, submitFailed: false }}
          disabled={disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          data={foodRestrictions}
          input={{
            value: guestRestrictions,
            onChange: handleChangeRestriction
          }}
        />
      </SeatFoodRestrict>
      <DeleteSeatButton
        disabled={disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
        onClick={removeSeat}>
        <Icon type="delete" />
      </DeleteSeatButton>
    </SeatRow>
  );
}
export default GuestSeatComponent;