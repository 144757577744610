import React, { Component, Fragment } from 'react';
import { Icon, message, Modal, Popconfirm, Tag, Tooltip } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton
} from '../../styles/BasicStyles';
import {
  SoftDeleteWedding,
  DownloadWeddings,
  UnblockMeetings,
  GetWeddingsPayments,
  TogglePaymentsToTeam
} from '../../infra/requests/WeddingRequests';
import { ArchiveWedding } from '../../infra/requests/ArchivedRequests';
import BaseButton from '../../components/buttons/BaseButton';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import WeddingsFilters from './WeddingsFilters';
import { LocalLabel, StateLabel } from './Styles';
import { connect } from 'react-redux';
import { ClearWedding } from '../../redux/wedding/wedding.actions';
import { bindActionCreators } from 'redux';
import { getInitials, getLocal, getLocalColor, getWeddingContacts, getWeddingEmails, getWeddingName } from '../../infra/services/wedding/weddingUtils';
import { GetColorSystemByType } from '../../infra/requests/ColorSystemRequests';
import ModalDeleteWedding from './DeleteWeddingModal';
import { GetTextColor } from '../../infra/services/text/textUtils';
import { DateColumn, SpanAttended, SpanNotes } from '../reserves/ReservesPage';
import { isCurrentUserAdmin } from '../../infra/helpers/Helpers';
import styled, { createGlobalStyle } from 'styled-components';
import Alerts from '../../components/alert/Alert';
import { WeddingStatus } from '../reserves/ReserveAgenda';
import WeddingsPaymentsFilters from './WeddingsPaymentsFilters';
import PaymentsTeamComplete from '../../assets/icons/payment_team_complete.svg';
import PaymentsTeamImcomplete from '../../assets/icons/payment_team_incomplete.svg';
import ReactSVG from 'react-svg';

export const SpanContact = styled.a`
  text-overflow: ellipsis;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
`;

export const SpanArea = styled.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  overflow: auto;
`;

export const SpanGraphics = styled.div`
  width: 100%;
  height: 110px;
  max-height: 110px;
  overflow: auto;
  display: grid;
  align-items: flex-start;
  justify-content: center;

  .notes {
    display: flex;
    flex-direction: column;
  }

  .notes-title {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    text-decoration: underline;
  }
`;

const IncompletePaymentsMessage = (
  <div>
    <div>Tem a certeza que quer marcar os pagamentos à equipa como incompletos?</div>
  </div>
);

const CompletePaymentsMessage = (
  <div>
    <div>Tem a certeza que quer marcar os pagamentos à equipa como incompletos?</div>
  </div>
);

class WeddingsPaymentsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    download: false,

    openModalDelete: false,
    weddingToDelete: null,

    filters: {
      startDate: moment.utc().subtract(7, 'days'),
      paymentsCompleted: false
    },
    needRefresh: false,

    columns: [
      {
        title: 'Data',
        width: '80px',
        render: data => {
          return data?.wedding && data.wedding?.date ?
            <DateColumn>
              <span className='date'>{moment.utc(data.wedding.date).format('DD-MM-YYYY')}</span>
              <span className='weekdayName'>{moment.utc(data.wedding.date).locale('pt').format('dddd')}</span>
            </DateColumn>
            : ''
        }
      },
      {
        title: 'Local',
        width: '50px',
        render: value => {
          const local = getLocalColor(value?.wedding, this.state.colors);
          return local ? (<LocalLabel bgColor={local.color} color={GetTextColor(local.color)}>{local.initials}</LocalLabel>) : null
        }
      },
      {
        title: 'Noivos',
        width: '80px',
        render: data => getWeddingName(data.wedding)
      },
      {
        title: 'Email',
        width: '60px',
        render: data => {
          return <Tooltip title={getWeddingEmails(data.wedding)}>
            <SpanContact onClick={($event) => this.copy($event, getWeddingEmails(data?.wedding))}>
              {getWeddingEmails(data.wedding)}
            </SpanContact>
          </Tooltip>
        }
      },
      {
        title: 'Tel',
        width: '60px',
        render: data => {
          return <Tooltip title={getWeddingContacts(data.wedding)}>
            <SpanContact onClick={($event) => this.copy($event, getWeddingContacts(data.wedding))}>
              {getWeddingContacts(data.wedding)}
            </SpanContact>
          </Tooltip>
        }
      },
      {
        title: 'Preço p/PAX',
        width: '70px',
        render: value => <CurrencyComponent value={value.wedding.price} />
      },
      {
        title: 'Mínimo PAX',
        width: '55px',
        dataIndex: 'wedding.min_people'
      },
      {
        title: 'PAX Previsto',
        width: '65px',
        render: value => value?.ourGuest && value?.ourGuest?.provisory_guests ? value?.ourGuest?.provisory_guests : ''
      },
      {
        title: 'Pago',
        width: '80px',
        render: data => {
          return <React.Fragment>
            <CurrencyComponent value={data?.budget && data?.budget?.payed ? data?.budget.payed : 0} />
            <div style={{ marginTop: 5 }}>
              {data?.payedPhase3 < 0
                ? <Tag color='red' key={data.id}>
                  {data?.budget?.payedPercentage !== null && data?.budget?.payedPercentage !== undefined ? (data?.budget?.payedPercentage).toFixed() : 0} %
                </Tag>
                : <span>{(data?.budget && data?.budget?.payedPercentage ? data.budget.payedPercentage : 0).toFixed() + '%'}</span>
              }
            </div>
          </React.Fragment>
        }
      },
      {
        title: 'Chegada à Quinta',
        width: '45px',
        render: value => {
          return value.ourDay ? value.ourDay.time : 'ND';
        }
      },
      {
        title: 'Estado Equipa',
        width: '70px',
        render: data => {
          const status = data.payments && data.payments && data.payments.length > 0 ? 5 :
            data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length > 0 ? 4 :
              data.teamComplete && data.teamComplete.length > 0 && data.teamComplete.filter(t => t.published).length === 0 ? 3 :
                data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length > 0 ? 2 :
                  data.teamBooking && data.teamBooking.length > 0 && data.teamBooking.filter(t => t.showEmployee).length === 0 ? 1 : 0;

          return status === 1 ? 'Encomenda em Processo' : status === 2 ? 'Encomenda Publicada (RH)' :
            status === 3 ? 'Equipa em Constituição' : status === 4 ? 'Equipa Publicada' :
              status === 5 ? 'Pagamentos Gerados' : 'Sem Encomenda';
        }
      },
      {
        title: 'Arquivado',
        width: '50px',
        className: 'align-center',
        render: data => {
          const arquived = data?.wedding?.status == WeddingStatus.ARCHIVED;
          return <Tag color={arquived ? "green" : 'red'}>{arquived ? 'Sim' : 'Não'}</Tag>;
        }
      },
      {
        title: 'Pagamentos à Equipa',
        width: '60px',
        render: data => {
          const color = data?.wedding?.paymentsCompleted ? 'green' : 'red';
          return <div style={{ display: 'inline-flex', alignContent: 'center', alignItems: 'center' }}>
            <Tag color={color} style={{ marginRight: 0 }}>
              {data?.wedding?.paymentsCompleted ? 'Completo' : 'Incompleto'}
            </Tag>
            <Tooltip title={data?.wedding?.paymentsCompleted ? 'Pagamentos incompletos' : 'Pagamentos completos'}>
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title={data?.wedding?.paymentsCompleted ? IncompletePaymentsMessage : CompletePaymentsMessage}
                  okText={'Confirmar'}
                  onConfirm={async () => await this.markPaymentsToTeam(data.wedding)}>
                  <ReactSVG src={data?.wedding?.paymentsCompleted ? PaymentsTeamImcomplete : PaymentsTeamComplete} />
                </Popconfirm>
              </TableButton>
            </Tooltip>
          </div>
        }
      },
      {
        title: 'Acções',
        width: '50px',
        render: data => (
          <div onClick={e => e.stopPropagation()}>
            <Tooltip title='Encomenda'>
              <TableButton onClick={e => this.manageTeamBooking(e, data)}>
                <Icon type="inbox" />
              </TableButton>
            </Tooltip>
            <Tooltip title='Equipa'>
              <TableButton onClick={e => this.manageWeddingTeam(e, data)}>
                <Icon type="team" />
              </TableButton>
            </Tooltip>
          </div>
        )
      }
    ],
    rows: [],
    total: 0,

    colors: {
      colorSys1: null,
      colorSys2: null
    },
  };

  componentDidMount = async () => {
    await this.updateDataTable();
    await this.getLocalColors();
  };

  getLocalColors = async () => {
    const resColorSys1 = await GetColorSystemByType(4);
    const colorSys1 = resColorSys1.success && resColorSys1.data ? resColorSys1.data.color : '#f7b5d6';
    const resColorSys2 = await GetColorSystemByType(5);
    const colorSys2 = resColorSys2.success && resColorSys2.data ? resColorSys2.data.color : '#b6d7a9';
    this.setState({ colors: { colorSys1, colorSys2 } });
  }

  copy = ($event, text) => {
    $event.stopPropagation();
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      document.execCommand('copy');
    }
    message.success('Campo copiado!');
  };

  openProcess = record => {
    const { history } = this.props;

    if (record?.wedding?.status == WeddingStatus.ARCHIVED) {
      return;
    }

    //Clear previous saved Wedding
    ClearWedding();
    history.push(`/wedding/${record.wedding._id}?page=WPAY`);
  };

  manageTeamBooking = (e, data) => {
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/team-booking/${data.wedding._id}?page=WPAY`);
  }

  manageWeddingTeam = (e, data) => {
    e.stopPropagation();
    const { history } = this.props;
    history.push(`/wedding-team/${data.wedding._id}?page=WPAY`);
  }

  markPaymentsToTeam = async (wedding) => {
    this.setState({ loading: true });
    const result = await TogglePaymentsToTeam(wedding?._id);

    if (result?.success && result?.data) {
      const paymentsCompleted = result?.data?.paymentsCompleted;
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: paymentsCompleted ? 'Pagamentos à equipa completo' : 'Pagamentos à equipa incompleto'
      });
      this.updateDataTable();
    } else {
      this.setState({ loading: true });
    }
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    if (this.state.loading) {
      this.setState({ needRefresh: true });
      return;
    }

    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;

      if (Array.isArray(filters?.dates) && filters?.dates.length === 0) {
        delete filters.dates;
      }

      const result = await GetWeddingsPayments(currentPage, pageSize, JSON.stringify(filters));

      this.setState({
        rows: result?.data && result.data.items ? result.data.items : [],
        total: result?.data && result.data.total ? result.data.total : 0,
        //currentPage: result?.data && result.data.page ? result.data.page : 1,
        pageSize: result?.data && result.data.limit ? result.data.limit : 30,
        loading: false
      }, () => {
        if (this.state.needRefresh) {
          this.setState({ needRefresh: false }, () => this.updateDataTable());
        }
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  handleFilterChange = filters => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  render() {
    const { history } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;
    const { colors } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>Pagamentos às Equipas</PageTitle>
            Lista de Casamentos
          </HeaderTitle>
          <HeaderButtonsContainer buttons={0}>
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer buttons={0}>
          <WeddingsPaymentsFilters loading={loading} queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            defaultPageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="team"
            emptyText={'There are no weddings'}
            total={total}
            rowKey={'wedding._id'}
            hasPagination={true}
            onPressRow={this.openProcess}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ wedding: state.wedding });

const mapActionToProps = dispatch => bindActionCreators({ ClearWedding }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(WeddingsPaymentsPage);
